<style>
/* .daterangepicker .calendars-container {
  margin-left: 20% !important;
  margin-top: -43% !important;
} */
</style>
<style lang="scss" scoped>
.graph {
  //   margin-top: 5%;
  .graph-left {
    .graph-left-text {
      position: absolute;
      top: 0%;
      left: 40%;
    }
  }
  img {
    width: 39%;
  }
  .graph-mid {
    margin-left: 20%;
  }
  .card {
    width: 100%;
    margin-top: 1%;
  }
}
.text-color-blue {
  color: #007bff;
}
.vue-daterange-picker {
  margin-top: 4%;
  margin-left: 26%;
}

.btn-active {
  color: #ffffff;
  background-color: #3699ff;
  border-color: #3699ff;
}
.card-header {
  background-color: #3699ff;
}
.dash {
  .card {
    .card-header {
      padding: 1rem 2.25rem;
    }
  }
}
</style>
<template>
  <!-- <h1>Dashboard</h1> -->
  <div class="dash">
    <!-- <div class="row">
      <div
        class="col-lg-3 col-sm-3 mb-3"
        v-for="(b, i) in getDashboardcardDate"
        :key="i"
      >
        <dashboard-card :data="b" :key="i"></dashboard-card>
      </div>
    </div> -->
    <div class="row graph">
      <div class="col-md-3">
        <div
          class="btn-group graph-mid"
          role="group"
          aria-label="Basic example"
        >
          <!-- <button
            type="button"
            class="btn btn-outline-primary"
            :class="[selectedBtn == 1 ? 'btn-active' : '']"
            @click="getseriesdata(1)"
          >
            Day
          </button> -->
          <button
            type="button"
            class="btn btn-outline-primary"
            :class="[selectedBtn == 2 ? 'btn-active' : '']"
            @click="getseriesdata(2)"
          >
            Week
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            :class="[selectedBtn == 3 ? 'btn-active' : '']"
            @click="getseriesdata(3)"
          >
            Month
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            :class="[selectedBtn == 4 ? 'btn-active' : '']"
            @click="getseriesdata(4)"
          >
            Year
          </button>
          <!-- <button
            type="button"
            class="btn btn-outline-primary"
            @click="opendatepicker"
            :class="[selectedBtn == 5 ? 'btn-active' : '']"
          >
            Date Range
          </button> -->
        </div>
      </div>
      <div class="col-md-5" style="margin-top: -17px;">
        <date-range-picker
          ref="picker"
          v-model="dateRange"
          :opens="'center'"
          :ranges="false"
          @update="getseriesdata(5)"
        ></date-range-picker>
      </div>
      <div class="card ">
        <div class="card-header">Appointments</div>
        <div class="card-body">
          <div class="row">
            <div
              class="col-md-3 col-lg-3 col-3"
              v-if="!this.currentUser.u.is_client"
            >
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="total"
                  v-model="app_radio"
                  @change="getseriesdata(selectedBtn)"
                />
                <label class="form-check-label" for="inlineRadio1">Total</label>
              </div>
              <div
                class="form-check form-check-inline"
                v-if="!this.currentUser.u.is_client"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="loaction"
                  v-model="app_radio"
                  @change="getseriesdata(selectedBtn)"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >By Location</label
                >
              </div>
              <div
                class="form-check form-check-inline"
                v-for="(s, i) in series"
                :key="i"
                v-show="app_radio == 'loaction'"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="s.name"
                  :id="s.name"
                  v-model="selected_location"
                />
                <label class="form-check-label" :for="s.name">
                  {{ s.name }}
                </label>
              </div>
            </div>
            <div class="col-md-9 col-lg-9 col-9">
              <v-data-loader v-if="getLoadingData"></v-data-loader>

              <chart
                v-else
                :key="index"
                :width="app_chart_width"
                :height="chart_height"
                :ctype="ctype"
                :options="options"
                :series="filtered_location"
              ></chart>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card"
        v-show="app_radio == 'total' && !this.currentUser.u.is_client"
      >
        <div class="card-header">Location</div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-9 col-lg-9 col-9">
              <v-data-loader v-if="getLoadingData"></v-data-loader>

              <chart
                v-else
                :key="index"
                :width="750"
                :ctype="'donut'"
                :options="pieLocaionOptions"
                :series="pielocationseries"
              ></chart>
            </div>
          </div>
        </div>
      </div>
      <div class="card" v-show="app_radio == 'total'">
        <div class="card-header">Service</div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-9 col-lg-9 col-9">
              <v-data-loader v-if="getLoadingData"></v-data-loader>

              <chart
                v-else
                :key="index"
                :width="650"
                :ctype="'donut'"
                :options="pieOptions"
                :series="pieseries"
              ></chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import Chart from "@/includes/graph/Chart";
// import DashboardCard from "@/components/Dashboard/DashboardCard";
import vDataLoader from "@/components/frontend/spinner.vue";

import { mapGetters } from "vuex";
import {
  FETCH_APPOITMENT_DATA_FOR_GRAPH,
  FETCH_APPOITMENT_LOCATION_DATA_FOR_GRAPH
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
export default {
  components: { DateRangePicker, Chart, vDataLoader },
  data() {
    let startDate = this.$moment().format("YYYY/MM/DD");
    let endDate = this.$moment().format("YYYY/MM/DD");

    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Analytics",
          route: ""
        }
      ],
      datacollection: null,
      format: { format: "dd/mm/yyyy" },
      dateRange: { startDate, endDate },
      options: {
        chart: {
          width: "10%",
          animations: {
            enabled: false
          },
          zoom: {
            enabled: false
          }
        },
        xaxis: {
          categories: []
        },
        stroke: {
          curve: "straight",
          width: 3
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top"
            }
          }
        },

        colors: []
      },
      pieseries: [],
      pieOptions: {
        chart: {
          type: "donut",
          toolbar: {
            show: true
          }
        },
        labels: [],
        colors: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 500
              },
              legend: {
                position: "top"
              }
            }
          }
        ]
      },
      pielocationseries: [],
      pieLocaionOptions: {
        chart: {
          type: "donut",
          toolbar: {
            show: true
          }
        },
        labels: [],
        colors: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 800
              },
              legend: {
                position: "bottom",
                horizontalAlign: "right"
              }
            }
          }
        ]
      },

      series: [],
      selectedBtn: 2,
      app_radio: "total",
      selected_location: [],
      index: 0,
      ctype: "bar",
      getLoadingData: false,
      chart_height: 400,
      app_chart_width: 600
    };
  },
  computed: {
    ...mapGetters(["currentUser", "getDashboardcardDate"]),
    filtered_location: function() {
      var series = this.series;
      let location = [...series];
      if (this.app_radio == "total") {
        return location;
      } else {
        const result = location.filter(loc => {
          if (this.selected_location.includes(loc.name)) {
            return loc;
          }
        });
        return result;
      }
    }
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getseriesdata();
    if (this.currentUser.u.is_client) {
      this.app_chart_width = 900;
    } else {
      this.app_chart_width = 600;
    }
  },
  methods: {
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    opendatepicker() {
      this.$refs.picker.togglePicker();
    },
    setdate(val) {
      let startDate = new Date();
      let endDate = new Date();
      switch (val) {
        case 1:
          startDate.setDate(startDate.getDate());
          this.format = { format: "dd/mm/yyyy" };
          this.dateRange = { startDate, endDate };
          break;
        case 2:
          this.format = { format: "dd/mm/yyyy" };
          startDate = this.$moment().startOf("isoWeek");
          endDate = this.$moment().endOf("isoWeek");
          this.dateRange = { startDate, endDate };
          break;
        case 3:
          this.format = { format: "mm/yyyy" };
          startDate = this.$moment().startOf("month");
          endDate = this.$moment().endOf("month");
          this.dateRange = { startDate, endDate };
          break;
        case 4:
          this.format = { format: "yyyy" };
          startDate = this.$moment().startOf("year");
          endDate = this.$moment().endOf("year");
          this.dateRange = { startDate, endDate };
          break;
        default:
          break;
      }
    },
    getseriesdata(val = 2) {
      this.selectedBtn = val;
      this.getLoadingData = true;
      this.setdate(val);
      let data = {
        daterange: this.dateRange,
        type: val,
        client: this.currentUser.u.is_client ? "c" : "sp",
        app_type: this.app_radio
      };
      if (this.app_radio == "total") {
        this.ctype = "bar";
        this.app_type = "total";
        this.chart_height = 400;
        this.options.xaxis.categories = [];
        this.options.colors = ["#3699ff"];

        this.$store
          .dispatch(FETCH_APPOITMENT_DATA_FOR_GRAPH, data)
          .then(res => {
            this.options.xaxis.categories = res.detail.date;
            this.series = [
              {
                name: "Total",
                data: res.detail.count
              }
            ];
            this.pieOptions.colors = res.popular_services.service_colors;
            this.pieOptions.labels = res.popular_services.name;
            this.pieseries = res.popular_services.values;
            if (!this.currentUser.u.is_client) {
              this.pieLocaionOptions.colors =
                res.popular_locations.location_colors;
              this.pieLocaionOptions.labels = res.popular_locations.name;
              this.pielocationseries = res.popular_locations.values;
            }
            // console.log(res);
            this.getLoadingData = false;
            this.index += 1;
          })
          .catch(e => {
            this.$toastr.e(e);
            this.getLoadingData = false;
          });
      } else {
        this.options.xaxis.categories = [];
        if (val == 1) {
          this.ctype = "bar";
        } else {
          this.ctype = "line";
        }
        this.chart_height = 600;
        this.$store
          .dispatch(FETCH_APPOITMENT_LOCATION_DATA_FOR_GRAPH, data)
          .then(res => {
            this.options.xaxis.categories = res.date;
            this.options.colors = res.location_colors;

            this.series = res.detail;
            let selected_location = [];
            res.detail.forEach(element => {
              selected_location.push(element.name);
            });
            this.selected_location = selected_location;
            this.getLoadingData = false;
            this.index += 1;
          })
          .catch(() => {
            this.$toastr.e("oops! something went wrong");
            this.getLoadingData = false;
          });
      }
    }
  }
};
</script>
