<template>
  <div>
    <apex-charts
      id="apexchart responsive-chart"
      :width="width"
      :type="ctype"
      :options="options"
      :series="series"
      :height="height"
    ></apex-charts>
  </div>
</template>
<script>
import ApexCharts from "vue-apexcharts";

export default {
  components: { ApexCharts },
  props: {
    options: {
      type: Object,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    width: {
      type: Number,
      default: 500
    },
    ctype: {
      type: String,
      default: "bar"
    },
    height: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {};
  },
  destroyed() {
    //   var chart = new ApexCharts(
    //     document.querySelector("#apexchart"),
    //     this.options
    //   );
    //   if (chart.ohYeahThisChartHasBeenRendered) {
    //     chart.destroy();
    //     chart.ohYeahThisChartHasBeenRendered = false;
    //   }
  }
};
</script>
